import React from "react";
import style from "./content.module.scss";
import logo from "../../static/image/mobile_image.png";
import logoL from "../../static/image/desktop_image.png";
import { Helmet } from "react-helmet-async";

const Content = () => {
  return (
    <div className={style.header}>
      <Helmet>
        {/* <title>
                    {productproduct.partNumber} {productproduct.name}{" "}
                  </title> */}
        <link rel="canonical" href={`https://zavediauto.ru/`} />
        <meta
          name="description"
          content="Грузовая разборка, разборка грузовых автомобилей, грузовые автозапчасти, грузовой ремонт, грузовой автомобиль, выкуп авто и продажа автомобилей, 
      truck, автопоезд"
        />
      </Helmet>
      <div className={style.text}>
        <img src={logo} alt="Small truck" className={style.small} />
        <img src={logoL} alt="Large truck" className={style.large} />
      </div>
      <div className={style.text}>
        <h1 className={style.ps}>Выкуп авто в Саратове</h1>
        <p className={style.p1}>
          Компания, Zavediauto.ru предлагает купить или обменять Ваш автомобиль.
          Мы имеем собственную площадку для продажи авто.
        </p>
        <p className={style.p4}>
          Позвоните по номеру{" "}
          <a className={style.p4} href="tel:+78452903590 ">
            {" "}
            +7(8452)90-35-90
          </a>
        </p>
        <h1 className={style.p3}>б/у запчасти для грузовых автомобилей</h1>
        <p className={style.p1}>
          Наша компания предлагает большой выбор б/у автозапчастей для
          европейских автомобилей в наличии. Также мы можем привезти запчасти на
          заказ, в том числе как новые, так и б/у."
        </p>
        <p className={style.p4}>
          Позвоните по номеру{" "}
          <a style={{ color: "white" }} href="tel:+79033281123">
            +79033281123
          </a>
        </p>
      </div>
    </div>
  );
};

export default Content;
