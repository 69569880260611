import React from "react";
import style from "./productItem.module.scss";
import MyBotton from "../UI/button/MyButton";
import { useNavigate } from "react-router-dom";

const ProductItem = (props) => {
  const navigate = useNavigate();
  const encodePartNumber = encodeURIComponent(props.product.partNumber);
  return (
    <div className={style.product}>
      <div className={style.product__content}>
        <strong>
          <h1 className={style.product__content}>
            {props.number}. {props.product.name}
          </h1>
        </strong>
        <div>
          <div style={{ display: "flex", gap: "0.5rem" }}>
            Номер детали:
            <h1 className={style.product__content}>
              {props.product.partNumber}
            </h1>
          </div>
          <div>Производитель: {props.product.model}</div>
          <div>В наличии: {props.product.quantity} шт.</div>
          <div>Цена: {props.product.price}р.</div>
        </div>
      </div>
      <div>
        <MyBotton
          onClick={() => navigate(`/product/${encodePartNumber}`)}
          product={props}
        >
          Информация
        </MyBotton>
        {/* <MyBotton onClick={() => props.remove(props.product)}>Удалить</MyBotton> */}
      </div>
    </div>
  );
};

export default ProductItem;
