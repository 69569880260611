import { Route, Routes } from "react-router-dom";
import Content from "../content/Content";
import About from "../about/About";
import ProductList from "../productList/ProductList";
import { useEffect, useState } from "react";
import dataj from "../features/xlsxToJson/data.json";
import Error from "../Error/Error";
import ProductPage from "../productPage/ProductPage";
import classes from "./appRoute.module.scss";

const AppRouter = () => {
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    if (dataj && dataj.length > 0) {
      setJsonData(dataj);
    }
  }, []);

  console.log(jsonData);
  return (
    <div className={classes.appR}>
      <Routes>
        <Route path="/" element={<Content />} />
        <Route
          path="/product"
          element={<ProductList product={jsonData} title="Список товаров" />}
        />
        <Route
          path="/product/:partNumber"
          element={<ProductPage product={jsonData} />}
        />
        <Route path="/About" element={<About />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
};

export default AppRouter;
