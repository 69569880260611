import React from 'react';

const MySelect = ({options, defaultValue, value, onChange}) => {
    return (
        <select 
        style={{color: 'white'}}
        value={value}
        onChange={event => onChange(event.target.value)}>
            <option disabled>{defaultValue}</option>
        {options.map(option => 
            <option key={option.value} value={option.value}>
                {option.name}
            </option> )}
        </select>
    );
};

export default MySelect;