import React, { useState } from "react";
import MyInput from "../input/MyInput";
import MyBotton from "../button/MyButton";

const ProductForm = ({ create }) => {
  const [inputValue, setInputValue] = useState({
    name: "",
    partNumber: "",
    model: "",
    quantity: "",
    price: "",
    internalCode: "",
  });

  const addNewProduct = (e) => {
    e.preventDefault();
    const newProduct = { ...inputValue, id: Date.now() };
    create(newProduct);
    setInputValue({
      name: "",
      partNumber: "",
      model: "",
      quantity: "",
      price: "",
      internalCode: "",
    });
  };
  return (
    <form style={{ padding: "1rem" }}>
      <MyInput
        value={inputValue.name}
        onChange={(e) => setInputValue({ ...inputValue, name: e.target.value })}
        type="text"
        placeholder="Название товара"
      />
      <MyInput
        value={inputValue.partNumber}
        onChange={(e) =>
          setInputValue({ ...inputValue, partNumber: e.target.value })
        }
        type="text"
        placeholder="Номер детали"
      />
      <MyInput
        value={inputValue.model}
        onChange={(e) =>
          setInputValue({ ...inputValue, model: e.target.value })
        }
        type="text"
        placeholder="Производитель"
      />
      <MyInput
        value={inputValue.quantity}
        onChange={(e) =>
          setInputValue({ ...inputValue, quantity: e.target.value })
        }
        type="text"
        placeholder="Количество"
      />
      <MyInput
        value={inputValue.price}
        onChange={(e) =>
          setInputValue({ ...inputValue, price: e.target.value })
        }
        type="text"
        placeholder="Цена"
      />
      <MyInput
        value={inputValue.internalCode}
        onChange={(e) =>
          setInputValue({ ...inputValue, internalCode: e.target.value })
        }
        type="text"
        placeholder="Внутренний номер товара"
      />
      <MyBotton onClick={addNewProduct}>Добавить товар</MyBotton>
    </form>
  );
};

export default ProductForm;
