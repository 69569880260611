import React from "react";

const Error = () => {
  return (
    <div>
      {" "}
      <h1 style={{ color: "#e88401" }}>
        Вы перешли на не существующую страницу...
      </h1>
    </div>
  );
};

export default Error;
