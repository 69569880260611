import React from "react";
import style from "./footer.module.scss";

const Footer = () => {
  return (
    <div>
      <p className={style.inf}>г.Саратов 2024г.</p>
    </div>
  );
};

export default Footer;
