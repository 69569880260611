import { useEffect, useState } from "react";
import classes from "./productPage.module.scss";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const ProductPage = ({ product }) => {
  const { partNumber } = useParams();
  const [filteredProduct, setFilteredProduct] = useState([]);
  console.log(product, "пришел массив?");

  useEffect(() => {
    if (product && Array.isArray(product)) {
      const filtered = product.filter((prod) => prod.partNumber === partNumber);
      setFilteredProduct(filtered);
      console.log(filtered, "filter work");
    }
  }, [product, partNumber]);
  console.log(filteredProduct, "проверь ключи");

  return (
    <div style={{ color: "#e88401" }}>
      <Link to="/product" style={{ color: "#e88401" }}>
        Назад
      </Link>
      <p className={classes.h3}> Информация о товаре </p>

      {filteredProduct.map((productproduct) => (
        <div>
          {productproduct.name !== null && <h1>{productproduct.name}</h1>}
          <div>
            {productproduct.partNumber !== "" && (
              <div style={{ display: "flex", gap: "0.5rem" }}>
                Номер детали:{" "}
                <Helmet>
                  <title>
                    {productproduct.partNumber} {productproduct.name}{" "}
                  </title>
                  <link
                    rel="canonical"
                    href={`https://zavediauto.ru/product/${productproduct.partNumber}`}
                  />
                  <meta
                    name="description"
                    content={productproduct.description}
                  />
                </Helmet>
                <h1 style={{ fontSize: "16px", margin: 0 }}>
                  {productproduct.partNumber}
                </h1>
              </div>
            )}
            {productproduct.model !== null && (
              <div>Производитель: {productproduct.model}</div>
            )}
            {productproduct.quantity !== null && (
              <div>В наличии: {productproduct.quantity} шт.</div>
            )}
            {productproduct.price !== null && (
              <div>Цена: {productproduct.price}р.</div>
            )}
            {productproduct.internalCode !== null && (
              <div>Внутренний номер: {productproduct.internalCode}</div>
            )}
            {productproduct.weight !== null && (
              <div>Масса: {productproduct.weight}</div>
            )}
            {productproduct.length !== null && (
              <div>Длина: {productproduct.length}</div>
            )}
            {productproduct.width !== null && (
              <div>Ширина: {productproduct.width}</div>
            )}
            {productproduct.height !== null && (
              <div>Высота: {productproduct.height}</div>
            )}
            {productproduct.description !== null && (
              <div>
                Описание:{" "}
                <span itemProp="description">{productproduct.description}</span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductPage;
