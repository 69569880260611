import React from "react";
import style from "./header.module.scss";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <div className={style.wrapper}>
      <h1 className={style.ps}>Zavediauto.ru </h1>
      <header className={style.header}>
        <NavLink className={style.nav} to="/">
          Главная
        </NavLink>
        <NavLink className={style.nav} to="/product">
          Товары
        </NavLink>
        <NavLink className={style.nav} to="/about">
          О компании
        </NavLink>
      </header>
      <div
        style={{ margin: " 15px 0" }}
      >
        <a style={{ textDecoration: "none", color: "#e88401"}} href="tel:+79033281123">
          Позвонить +79033281123
        </a>
      </div>
    </div>
  );
};

export default Header;
