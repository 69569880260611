import React, { useEffect, useMemo, useState } from "react";
import ProductItem from "../productItem/ProductItem";
import ProductForm from "../UI/produckForm/ProductForm";
import ProductFilter from "../productFilter/ProductFilter";
import MyModal from "../myModal/MyModal";
// import ExcelToJsonConverter from "../features/xlsxToJson/ExcelToJsonConverter";

const ProductList = ({ product, title, remove }) => {
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState({ sort: "", query: "" });
  const [modal, setModal] = useState(false);

  const sortedProducts = useMemo(() => {
    if (filter.sort && products && products.length > 0) {
      return [...products].sort((a, b) => {
        // Проверяем, являются ли значения строками перед вызовом localeCompare()
        const valueA = String(a[filter.sort]);
        const valueB = String(b[filter.sort]);
        return valueA.localeCompare(valueB);
      });
    }
    return products;
  }, [filter.sort, products]);

  const sortedAndSearchedProducts = useMemo(() => {
    console.log(sortedProducts, "что тут");
    if (sortedProducts && sortedProducts.length > 0) {
      return sortedProducts.filter((prod) =>
        prod.name.toLowerCase().includes(filter.query.toLowerCase())
      );
    }
  }, [filter.query, sortedProducts]);

  const createInputValue = (newProduct) => {
    setProducts([...products, newProduct]);
    setModal(false);
  };

  const removeProduct = (product) => {
    setProducts(products.filter((p) => p.id !== product.id));
  };

  useEffect(() => {
    setProducts(product);
  }, [product]);

  return (
    <div>
      {/* <ExcelToJsonConverter /> */}
      {/* <MyBotton style={{ marginTop: 30 }} onClick={() => setModal(true)}>
      {/* Создать товар */}
      {/* </MyBotton> */}
      <MyModal visible={modal} setVisible={setModal}>
        <ProductForm create={createInputValue} />
      </MyModal>
      <hr style={{ margin: " 15px 0" }} />
      <ProductFilter filter={filter} setFilter={setFilter} />
      {sortedAndSearchedProducts && sortedAndSearchedProducts.length > 0 ? (
        <div>
          <h1 style={{ textAlign: "center", color: "white" }}>{title}</h1>
          {sortedAndSearchedProducts.map((product, index) => (
            <ProductItem
              remove={removeProduct}
              number={index + 1}
              product={product}
            />
          ))}
        </div>
      ) : (
        <h1 style={{ color: "white", textAlign: "center" }}>
          Товары не найдены!
        </h1>
      )}
    </div>
  );
};

export default ProductList;
