import React from "react";
import Footer from "./component/footer/Footer";
import Header from "./component/header/Header";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./component/appRouter/AppRouter";
import Container from "./container/Container";

function App() {
  return (
    <>
      <BrowserRouter>
        <Container>
          <Header />
          <AppRouter />
          <Footer />
        </Container>
      </BrowserRouter>
    </>
  );
}

export default App;
